<template>
  <div>
    <VueCalScheduler
        v-if="!selectedDay"
        :events="events"
        @itemSelected="onItemSelected"
        @getData="getData"
    />
    <div v-if="selectedDay" class="day-details p-5">
      <div class="day-details__header d-flex align-items-center border-bottom border-bottom-light-dark pb-5">
        <span class="fas fa-chevron-left font-size-h2 cursor-pointer mr-5" @click="selectedDay = null"/>
        <span class="font-size-h6 font-weight-bold">{{ $t('label.log') }}:</span>
      </div>
      <div class="day-details__body pt-5 px-7">
        <div v-if="history" class="row">
          <div class="col-md-4 mb-3">
            <div v-for="open in history" class="d-flex align-items-center mt-2">
              <div class="mr-3">
                <span class="fad fa-scrubber font-size-sm"></span>
              </div>
              <div class="text-dark-75 flex-grow-1 font-weight-bold font-size-h6 mr-2">
                {{ open.date }}
              </div>
              <div class="text-muted flex-grow-1 font-weight-bold mt-lg-0 mt-3 mr-2">
                {{ open.key_type }}
              </div>
              <div class="text-muted font-weight-bold mt-lg-0 mt-3">
                {{ open.booking }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="lead text-center mt-5">
          {{ $t('description.no_data') }}
        </div>
      </div>
    </div>
    <download-data-form v-if="events.length" :apartment-id="apartmentId"/>
    <div v-if="!events.length">
      <div class="lead text-center my-5">
        {{ $t('description.no_data') }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import VueCalScheduler from '@/components/elements/calendars/VueCalScheduler';
import DownloadDataForm from '@/components/apartments/doors-history/DownloadDataForm';

export default {
  components: {
    VueCalScheduler,
    DownloadDataForm
  },
  props: {
    apartmentId: {
      default: null
    }
  },
  data: () => ({
    selectedDay: null
  }),
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      events: 'eKeysStore/EVENTS',
      history: 'eKeysStore/HISTORY'
    })
  },
  watch: {
    events: {
      immediate: true,
      handler(data) {
        if (data) {
          this.$emit('lastOpenedAt', data[0]?.date);
        }
      }
    },
    selectedDay(date) {
      if (date) {
        this.$store.commit('eKeysStore/CLEAR_HISTORY');
        this.$store.dispatch('eKeysStore/GET_HISTORY', {
          apartmentID: this.apartmentId,
          date: new Date(date).toDateString()
        })
      }
    }
  },
  methods: {
    getData() {
      this.$store.dispatch('eKeysStore/GET_EVENTS', this.apartmentId);
    },
    onItemSelected(item) {
      this.selectedDay = item.start;
    }
  }
}
</script>

<style lang="scss" scoped>
.vue-cal-scheduler::v-deep {
  .vuecal__cell {
    height: 50px;
  }
}
</style>