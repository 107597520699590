<template>
  <b-modal centered hide-header hide-footer size="lg" id="qr-url-modal" ref="qr-url-modal">
    <div class="d-flex flex-column align-items-center">
      <div class="text-center mb-5" id="qr-url">
        {{ url }}
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button
              type="button"
              class="btn btn-hover-bg-success btn-text-success btn-hover-text-white border-0 font-weight-bold mr-2"
              :disabled="urlCopied"
              @click="copyToClipboard"
      >
        {{ urlCopied ? $t('label.copied') : $t('btn.copy_to_clipboard') }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    url: {
      default: '',
    }
  },
  data: () => ({
    urlCopied: false
  }),
  methods: {
    hideModal() {
      this.$refs['qr-url-modal'].hide();
    },
    copyToClipboard() {
      const range = document.createRange();
      range.selectNode(document.getElementById('qr-url'));
      window.getSelection().removeAllRanges(); // clear current selection
      window.getSelection().addRange(range); // to select text
      document.execCommand('copy');
      window.getSelection().removeAllRanges();// to deselect
      this.urlCopied = true;
      setTimeout(() => {
        this.hideModal();
        this.urlCopied = false;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.qr-input::v-deep input {
  padding-right: 65px;
}

.btn-copy {
  position: absolute;
  top: 22px;
  right: 22px;
}
</style>