<template>
  <div class="row mb-5">
    <div class="col-md-4 text-center flex-lg-fill my-1">
      <div class="d-flex flex-column text-dark-75">
        <span class="text-muted font-size-sm">{{ $t('label.balance') }}</span>
        <span class="font-weight-bolder font-size-h5"
              :class="details.debt === 1 ? 'text-danger' : 'text-success'">
                {{ `${details.balance.value} ${details.balance.currency_symbol}` }}
              </span>
      </div>
    </div>
    <div class="col-md-4 text-center flex-lg-fill my-1">
      <div class="d-flex flex-column text-dark-75">
        <span class="text-muted font-size-sm">{{ $t('label.monthly_tariff') }}</span>
        <span class="font-weight-bolder font-size-h5">
          {{ `${details.price.value} ${details.price.currency_symbol}` }}
        </span>
      </div>
    </div>
    <div class="col-md-4 text-center flex-lg-fill my-1">
      <div class="d-flex flex-column text-dark-75">
        <span class="text-muted font-size-sm">{{ $t('label.start_date') }}</span>
        <span class="font-weight-bolder font-size-h5">
          {{ data.ownershipDate }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {type: Object, default: () => {}}
  },
  computed: {
    details() {
      return this.data?.service || {};
    }
  }
}
</script>