<template>
  <div class="p-5">
    <div class="d-flex justify-content-between align-items-center">
      <DatetimePickerFormGroup
          class="mb-3 mr-2 w-md-50"
          :value="form.date.from"
          :placeholder="$t('placeholder.from')"
          :with-icon="false"
          :max-datetime="form.date.to"
          allow-clear
          @change="form.date.from = $event"
      />
      <DatetimePickerFormGroup
          class="mb-3 w-md-50"
          :value="form.date.to"
          :placeholder="$t('placeholder.to')"
          :with-icon="false"
          :min-datetime="form.date.from"
          allow-clear
          @change="form.date.to = $event"
      />
    </div>
    <button type="button"
            class="btn btn-light-primary d-flex m-auto align-items-center font-weight-bold px-12"
            @click="downloadData"
    >
      <span class="fas fa-file-pdf mr-1"/>
      {{ $t('btn.download') }}
    </button>
  </div>
</template>

<script>
import DatetimePickerFormGroup from '@/components/elements/form-groups/DatetimePickerFormGroup';

export default {
  components: {
    DatetimePickerFormGroup
  },
  props: {
    apartmentId: {
      default: null
    }
  },
  data: () => ({
    form: {
      date: {
        from: null,
        to: null
      }
    }
  }),
  methods: {
    async downloadData() {
      await this.$store.dispatch('eKeysStore/EXPORT_HISTORY', {
        apartmentID: this.apartmentId,
        filters: this.form
      })
    }
  }
}
</script>