<template>
  <div>
  <div class="d-flex justify-content-between align-items-center">
    <DatetimePickerFormGroup
        class="mb-3 mr-2 w-md-50"
        :value="form.date_from"
        :placeholder="$t('placeholder.from')"
        :with-icon="false"
        :max-datetime="form.date_to"
        allow-clear
        @change="form.date_from = $event"
    />
    <DatetimePickerFormGroup
        class="mb-3 w-md-50"
        :value="form.date_to"
        :placeholder="$t('placeholder.to')"
        :with-icon="false"
        :min-datetime="form.date_from"
        allow-clear
        @change="form.date_to = $event"
    />
  </div>
    <div class="d-flex justify-content-center">
      <button type="button"
              class="btn btn-light-primary font-weight-bold px-12 mr-3"
              @click="downloadData('pdf')"
      >
        <span class="fas fa-file-pdf mr-1"/>
        {{ $t('btn.monthly_report') }}
      </button>
<!--      <button type="button"-->
<!--              class="btn btn-light-primary font-weight-bold px-12"-->
<!--              @click="downloadData('xlsx')"-->
<!--      >-->
<!--        <span class="fas fa-file-excel mr-1"/>-->
<!--        {{ $t('btn.download') }}-->
<!--      </button>-->
      <button type="button"
              class="btn btn-light-primary font-weight-bold px-12 mr-3"
              @click="downloadData('counterPdf')"
      >
        <span class="fas fa-file-pdf mr-1"/>
        {{ $t('btn.daily_report') }}
      </button>
    </div>
  </div>
</template>

<script>
import DatetimePickerFormGroup from '@/components/elements/form-groups/DatetimePickerFormGroup';

export default {
  components: {
    DatetimePickerFormGroup
  },
  props: {
    apartmentId: {default: null},
    data: {type: Object, default: () => {}}
  },
  data: () => ({
    form: {
      date_from: null,
      date_to: null
    }
  }),
  watch: {
    data: {
      immediate:true,
      handler(data) {
        if (data) {
          this.form.date_from = data.ownershipDate;
          this.form.date_to = data.currentDate;
        }
      },
      deep: true
    }
  },
  methods: {
    async downloadData(type) {
      await this.$store.dispatch('apartmentElectricityStore/GET_FILE', {
        apartmentID: this.apartmentId,
        filters: this.form,
        type: type
      })
    }
  }
}
</script>