<template>
  <!-- begin::Page loader -->
  <div class="d-flex justify-content-center align-items-center table-loader">
    <div class="d-flex align-items-center table-loader__wrapper px-5 py-3" :class="{'bg-white': withText}">
      <div class="spinner-border ml-auto text-primary" role="status" aria-hidden="true"></div>
      <span v-if="withText" class="ml-3">processing...</span>
    </div>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: "Loader",
  props: {
    logo: String,
    spinnerClass: String,
    withText: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.table-loader::v-deep {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;

  & .table-loader__wrapper {
    border-radius: 4px;
  }
}
</style>
