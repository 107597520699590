<template>
  <div>
    <div v-if="data && data.service">
      <HeaderData :data="data"/>
      <div class="separator separator-solid my-5"/>
      <DownloadDataForm :apartment-id="apartmentId" :data="data"/>
    </div>
    <div v-else>
      <div class="lead text-center my-5">
        {{ $t('description.no_data') }}
      </div>
    </div>
  </div>
</template>

<script>
import HeaderData from './services/HeaderData';
import DownloadDataForm from './services/DownloadDataForm';

export default {
  components: {
    HeaderData,
    DownloadDataForm
  },
  props: {
    apartmentId: {default: null},
    data: {type: Object, default: () => {}}
  }
}
</script>