<template>
  <div class="row mb-5">
    <div class="col-md-3 text-center flex-lg-fill my-1">
      <div class="d-flex flex-column text-dark-75">
        <span class="text-muted font-size-sm">{{ $t('label.counter_id') }}</span>
        <span class="font-weight-bolder font-size-h5">
          {{ details.counter_id }}
        </span>
      </div>
    </div>
    <div class="col-md-3 text-center flex-lg-fill my-1">
      <div class="d-flex flex-column text-dark-75">
        <span class="text-muted font-size-sm">{{ $t('label.status') }}</span>
        <span class="font-weight-bolder font-size-h5">
          {{ details.status }}
        </span>
      </div>
    </div>
    <div class="col-md-3 text-center flex-lg-fill my-1">
      <div class="d-flex flex-column text-dark-75">
        <span class="text-muted font-size-sm">{{ $t('label.balance') }}</span>
        <span class="font-weight-bolder font-size-h5" :class="details.debt === 1 ? 'text-danger' : 'text-success'">
          {{ `${details.balance.value} ${details.balance.currency_symbol}` }}
        </span>
      </div>
    </div>
    <div class="col-md-3 text-center flex-lg-fill my-1">
      <div class="d-flex flex-column text-dark-75">
        <span class="text-muted font-size-sm">{{ $t('label.last_update') }}</span>
        <span class="font-weight-bolder font-size-h5">
         {{ details.last_update }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {type: Object, default: () => {}}
  },
  computed: {
    details() {
      return this.data?.electricity || {};
    }
  }
}
</script>
